import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { Alert, Box, Dialog, Divider, Paper, Snackbar, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Stock, StockSizeEnum, StockUnitEnum } from "../../api";
import { sizeOptionsMap } from "../../constants/stock-attributes";
import { CartContext } from "../../context/CartContext";
import { ContentfulContext } from "../../context/ContentfulContext";
import { GoogleServicesContext } from "../../context/GoogleServicesContext";
import { Cart, Product } from "../../models/schema";
import { getButton, getValidationMsg } from "../../querries/getters";
import { colors } from "../../theme/colors";
import { gaAction, gaCategory } from "../../utils/analytics";
import {
  formatPrice,
  formatStock,
  getImagePath,
  getPriceWithTax,
  roundNumber
} from "../../utils/utilities";
import Button, { ButtonSize, ButtonVariant } from "../utils/Button";
import ButtonContainer from "../utils/ButtonContainer";
import Input from "../utils/Input";

export const AddToCart = ({ product }: { product: Stock }) => {
  const { content } = useContext(ContentfulContext);
  const {
    dialogTitle,
    size,
    price,
    piece,
    quantityMeters,
    quantityPieces,
    totalMeters,
    totalPieces,
    packagesCount,
    priceSum,
    itemNoStock,
    withTax,
    withoutTax,
    addedToCart: addedToCartMsg
  } = content?.cart as Cart;
  const { meterUnit, pieceUnit, pieceUnit2, pieceUnit3 } = content?.product as Product;
  const buttons = content?.buttonsCollection?.items;
  const validations = content?.validationsCollection?.items;

  const theme = useTheme();

  const { addItem } = useContext(CartContext);
  const { analyticsEvent } = useContext(GoogleServicesContext);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);

  const [packageCount, setPackagesCount] = useState(1);

  const [imageError, setImageError] = useState(false);

  const pricePerPackage = product?.pricePerPackage ?? 0;
  const pricePerPackageWithTax = getPriceWithTax(pricePerPackage, product?.sellingRateVAT ?? 0);

  const totalSum = packageCount * pricePerPackage;
  const totalSumWithTax = getPriceWithTax(totalSum, product?.sellingRateVAT ?? 0);

  const totalUnits = roundNumber(packageCount * (product.amountPerPackage ?? 0));
  const unit =
    product.unit === StockUnitEnum.Meter
      ? meterUnit
      : totalUnits === 1
      ? pieceUnit
      : totalUnits >= 2 && totalUnits < 5
      ? pieceUnit2
      : pieceUnit3;

  const packagesOnStock = Math.floor(product?.packagesOnStock ?? 0);

  const tileImagePath = product?.tileFaceImages?.[0];

  type Form = {
    amount: string;
  };

  const controls: Form = {
    amount: "amount"
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = () => {
    addItem({
      ean: product.ean,
      quantity: packageCount
    });

    setOpen(false);
    resetDialog();

    setAddedToCart(true);
    analyticsEvent(gaCategory.addToCart, gaAction.click, product.ean);
  };

  const resetDialog = () => {
    reset();
    setPackagesCount(1);
  };

  if (
    !product ||
    isNaN(Number(product.price)) ||
    isNaN(Number(product.packagesOnStock)) ||
    isNaN(Number(product.amountPerPackage))
  )
    return <></>;

  return (
    <>
      <Button
        onClick={() => {
          if ((packagesOnStock as number) > 0) {
            resetDialog();
            setValue(controls.amount, product?.amountPerPackage ?? 0);
            setOpen(true);
          } else {
            setAlert(true);
          }
        }}
        label={getButton(buttons, "addToCart")}
        variant={ButtonVariant.primary}
        size={ButtonSize.full}
        sx={{
          width: { xs: "100%", md: theme.spacing(50) }
        }}
      />

      <Snackbar
        open={!!alert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setAlert(false)}>
        <Alert
          onClose={() => setAlert(false)}
          severity="warning"
          sx={{
            display: "flex",
            alignItems: "center"
          }}>
          {itemNoStock as string}
        </Alert>
      </Snackbar>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
        <Paper
          component={"form"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 5
          }}>
          <Typography variant="h4" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 6 }}>
            {dialogTitle}
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: { xs: "column", md: "row" }
            }}
            mb={3}>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                width: "100%",
                justifyContent: "center",
                mr: { xs: 0, md: 5 },
                mb: 2
              }}>
              <Typography variant="body2" fontWeight="bold">
                {product.name}
              </Typography>
            </Box>
            {tileImagePath && !imageError ? (
              <Box
                component="img"
                onError={() => setImageError(true)}
                src={getImagePath(tileImagePath) as string}
                sx={{ mr: { xs: 0, md: 5 }, height: theme.spacing(20) }}
                mb={3}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 5,
                  mr: { xs: 0, md: 5 }
                }}>
                <ImageNotSupportedIcon
                  sx={(theme) => ({
                    fontSize: { xs: theme.spacing(5), md: theme.spacing(10) },
                    color: colors.brown
                  })}
                />
              </Box>
            )}
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: "100%",
                  mr: { xs: 0, md: 5 },
                  mb: 3
                }}>
                <Typography variant="body2" fontWeight="bold">
                  {product.name}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  mr: { xs: 0, md: 5 },
                  mb: 2
                }}>
                <Typography variant="body2">{size}:</Typography>
                <Typography variant="body2" fontWeight="bold">
                  {sizeOptionsMap[product.size as StockSizeEnum]}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  mr: { xs: 0, md: 5 },
                  mb: 2
                }}>
                <Typography variant="body2">{price} :</Typography>
                <Typography variant="body2" fontWeight="bold">
                  {formatPrice(pricePerPackage ?? 0)} ({withoutTax})
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  mr: { xs: 0, md: 5 },
                  mb: 2
                }}>
                <div />
                <Typography variant="body2">
                  {formatPrice(pricePerPackageWithTax ?? 0)} ({withTax})
                </Typography>
              </Box>
            </Box>
          </Box>

          <Divider sx={{ width: "100%", mb: 3 }} />

          <Input
            autoFocus
            required
            id={controls.amount}
            label={
              product.unit === StockUnitEnum.Meter
                ? (quantityMeters as string)
                : (quantityPieces as string)
            }
            type="number"
            error={!!errors.amount}
            helperText={errors.amount && (errors.amount.message as string)}
            name={controls.amount}
            onChange={() => {
              if (!packagesOnStock || !product.amountPerPackage) {
                setPackagesCount(0);
                return;
              }

              const { amount } = getValues();
              const amountValue = Number(amount);

              const amountOfPackagesOnStock =
                (packagesOnStock as number) * (product.amountPerPackage as number);

              if (amount <= 0) {
                setPackagesCount(0);
                return;
              }

              if (amountValue > 0 && amountValue <= amountOfPackagesOnStock) {
                const calculated = Math.ceil(
                  Math.round((amountValue / (product.amountPerPackage as number)) * 100) / 100
                );
                setPackagesCount(calculated);
              } else {
                setPackagesCount(amountOfPackagesOnStock);
              }
            }}
            rules={{
              required: getValidationMsg(validations, "required"),
              min: {
                value: 0,
                message: getValidationMsg(validations, "positive")
              },
              max: {
                value: (packagesOnStock as number) * (product.amountPerPackage as number),
                message: `${getValidationMsg(validations, "max")} ${formatStock(
                  roundNumber((packagesOnStock as number) * (product.amountPerPackage as number))
                )}`
              }
            }}
            control={control}
          />

          <Box sx={{ width: "100%" }} mb={3}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mr: { xs: 0, md: 5 }
              }}>
              <Typography variant="body2">{packagesCount}</Typography>
              <Typography variant="body2" fontWeight="bold" mr={1}>
                {packageCount} {piece}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: "100%" }} mb={3}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mr: { xs: 0, md: 5 }
              }}>
              <Typography variant="body2">
                {product.unit === StockUnitEnum.Meter
                  ? (totalMeters as string)
                  : (totalPieces as string)}
              </Typography>
              <Typography variant="body2" fontWeight="bold">
                {formatStock(totalUnits)} {unit}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ width: "100%", mb: 3 }} />

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mr: 5
              }}>
              <Typography variant="h5" sx={{ fontWeight: "regular" }}>
                {priceSum}
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {formatPrice(totalSum)} ({withoutTax})
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mr: { xs: 0, md: 5 }
              }}>
              <div />

              <Typography variant="h5" fontWeight={100}>
                {formatPrice(totalSumWithTax)} ({withTax})
              </Typography>
            </Box>
          </Box>

          <ButtonContainer sx={{ mt: 5 }}>
            <Button
              onClick={() => setOpen(false)}
              label={getButton(buttons, "cancel")}
              variant={ButtonVariant.secondary}
              sx={{
                width: theme.spacing(40)
              }}
            />
            <Button
              onClick={handleSubmit(onSubmit)}
              label={getButton(buttons, "addToCart")}
              variant={ButtonVariant.primary}
              sx={{
                width: theme.spacing(40)
              }}
            />
          </ButtonContainer>
        </Paper>
      </Dialog>

      <Snackbar
        open={addedToCart}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setAddedToCart(false)}>
        <Alert
          onClose={() => setAddedToCart(false)}
          severity="success"
          sx={{
            display: "flex",
            alignItems: "center"
          }}>
          {addedToCartMsg as string}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddToCart;
