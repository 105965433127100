import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, InputAdornment, Paper, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorResponse } from "../../api";
import Button, { ButtonVariant } from "../../components/utils/Button";
import ButtonContainer from "../../components/utils/ButtonContainer";
import Input from "../../components/utils/Input";
import NavButton from "../../components/utils/NavButton";
import PageTitle from "../../components/utils/PageTitle";
import RenderContent from "../../components/utils/RenderContent";
import { CHANGE_PASSWORD_REDIRECT } from "../../constants/global";
import { routes } from "../../constants/routes";
import { ApiContext } from "../../context/ApiContext";
import { ContentfulContext } from "../../context/ContentfulContext";
import { ErrorContext } from "../../context/ErrorContext";
import { GoogleServicesContext } from "../../context/GoogleServicesContext";
import { UserContext } from "../../context/UserContext";
import { Account, Language } from "../../models/schema";
import { getButton, getValidationMsg } from "../../querries/getters";
import { gaAction, gaCategory, gaLabel } from "../../utils/analytics";
import { getItem, removeItem, setItem } from "../../utils/storage";
import OperationResult, { OperationResultType } from "./OperationResult";

const PasswordChangePage = () => {
  const { authApi } = useContext(ApiContext);
  const { content } = useContext(ContentfulContext);
  const { analyticsEvent } = useContext(GoogleServicesContext);
  const {
    changePasswordTitle,
    changePasswordDescription,
    changePasswordSuccess,
    changePasswordFailure,
    oldPassword,
    newPassword,
    newPasswordAgain
  } = content?.account as Account;
  const { validationErrorMessage } = content as Language;

  const buttons = content?.buttonsCollection?.items;
  const validations = content?.validationsCollection?.items;

  const { setError } = useContext(ErrorContext);
  const { setUser } = useContext(UserContext);

  const [result, setResult] = useState<OperationResultType>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>();

  const navigate = useNavigate();

  useEffect(() => {
    const redirect = getItem(CHANGE_PASSWORD_REDIRECT);

    if (redirect) {
      removeItem(CHANGE_PASSWORD_REDIRECT);
      navigate(routes.home);
    }
  }, []);

  type Form = {
    oldPassword: string;
    newPassword: string;
    newPasswordAgain: string;
  };

  const controls: Form = {
    oldPassword: "oldPassword",
    newPassword: "newPassword",
    newPasswordAgain: "newPasswordAgain"
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = (data: unknown) => {
    setLoading(true);
    const { oldPassword, newPassword } = data as Form;

    authApi
      .changePassword({
        changePasswortDto: {
          oldPassword,
          newPassword
        }
      })
      .then(() => {
        setResult("SUCCESS");
        setLoading(false);
        setItem(CHANGE_PASSWORD_REDIRECT, true);
        analyticsEvent(gaCategory.passwordChange, gaAction.submit, gaLabel.success);

        authApi
          .logout()
          .then(() => {
            setUser(undefined);
          })
          .catch(() => {
            setUser(undefined);
          });
      })
      .catch((error) => {
        setLoading(false);
        analyticsEvent(gaCategory.passwordChange, gaAction.submit, gaLabel.error);

        if (error?.response?.status === 401) {
          setResult("ERROR");
          return;
        }

        error.response.json().then((json: ErrorResponse) => {
          console.error(json);

          if (json?.errorCode) {
            setErrorCode(json?.errorCode);
          }

          // Bussiness logic errors are 4xx
          if (Math.floor(error?.response?.status / 100) === 4) {
            setResult("ERROR");
            return;
          }

          // Otherwise it's an unexpected server error
          setError(error);
        });
      });
  };

  const resetForm = () => {
    reset();
    setResult(null);
  };

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewAgainPassword, setShowNewAgainPassword] = useState(false);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box>
      <PageTitle title={changePasswordTitle} />
      <Typography variant="h1" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 8 }}>
        {changePasswordTitle}
      </Typography>
      <RenderContent
        content={changePasswordDescription?.json}
        sx={{ mb: 8, textAlign: "center" }}
      />

      {!result && (
        <Paper
          component={"form"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4
          }}>
          <Input
            autoFocus
            required
            autoComplete="old-password"
            id={controls.oldPassword}
            label={oldPassword as string}
            type={showOldPassword ? "text" : "password"}
            error={!!errors.oldPassword}
            helperText={errors.oldPassword && (errors.oldPassword.message as string)}
            name={controls.oldPassword}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowOldPassword((show) => !show)}
                    onMouseDown={handleMouseDownPassword}>
                    {showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Input
            required
            autoComplete="new-password"
            id={controls.newPassword}
            label={newPassword as string}
            type={showNewPassword ? "text" : "password"}
            error={!!errors.newPassword}
            helperText={errors.newPassword && (errors.newPassword.message as string)}
            name={controls.newPassword}
            rules={{
              required: getValidationMsg(validations, "required"),
              validate: (val: string) => {
                if (watch("newPasswordAgain") !== val) {
                  return getValidationMsg(validations, "passwordsMatch");
                }

                if (watch("oldPassword") === val) {
                  return getValidationMsg(validations, "oldSameAsNew");
                }
              }
            }}
            control={control}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword((show) => !show)}
                    onMouseDown={handleMouseDownPassword}>
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Input
            required
            autoComplete="new-password-again"
            id={controls.newPasswordAgain}
            label={newPasswordAgain as string}
            type={showNewAgainPassword ? "text" : "password"}
            error={!!errors.newPasswordAgain}
            helperText={errors.newPasswordAgain && (errors.newPasswordAgain.message as string)}
            name={controls.newPasswordAgain}
            rules={{
              required: getValidationMsg(validations, "required"),
              validate: (val: string) => {
                if (watch("newPassword") !== val) {
                  return getValidationMsg(validations, "passwordsMatch");
                }
              }
            }}
            control={control}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewAgainPassword((show) => !show)}
                    onMouseDown={handleMouseDownPassword}>
                    {showNewAgainPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <ButtonContainer sx={{ mt: 5 }}>
            <Button
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              label={getButton(buttons, "changePassword")}
              variant={ButtonVariant.primary}
            />
          </ButtonContainer>
        </Paper>
      )}

      {result && (
        <OperationResult
          result={result}
          successTitle={changePasswordSuccess as string}
          errorTitle={
            errorCode
              ? `${validationErrorMessage} ${getValidationMsg(validations, errorCode)}`
              : (changePasswordFailure as string)
          }>
          {result === "SUCCESS" && (
            <NavButton
              route={routes.login}
              onClick={() => analyticsEvent(gaCategory.login, gaAction.click)}
              label={getButton(buttons, "login")}
              variant={ButtonVariant.primary}
            />
          )}

          {result === "ERROR" && (
            <Button
              onClick={resetForm}
              label={getButton(buttons, "tryAgain")}
              variant={ButtonVariant.primary}
            />
          )}
        </OperationResult>
      )}
    </Box>
  );
};

export default PasswordChangePage;
