import { Box, Paper, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorResponse } from "../../api";
import Button, { ButtonVariant } from "../../components/utils/Button";
import ButtonContainer from "../../components/utils/ButtonContainer";
import Input from "../../components/utils/Input";
import NavButton from "../../components/utils/NavButton";
import PageTitle from "../../components/utils/PageTitle";
import RenderContent from "../../components/utils/RenderContent";
import { routes } from "../../constants/routes";
import { ApiContext } from "../../context/ApiContext";
import { ContentfulContext } from "../../context/ContentfulContext";
import { ErrorContext } from "../../context/ErrorContext";
import { GoogleServicesContext } from "../../context/GoogleServicesContext";
import { Account, Language } from "../../models/schema";
import { getButton, getValidationMsg } from "../../querries/getters";
import { gaAction, gaCategory, gaLabel } from "../../utils/analytics";
import OperationResult, { OperationResultType } from "./OperationResult";

const PasswordResetPage = () => {
  const { authApi } = useContext(ApiContext);
  const { analyticsEvent } = useContext(GoogleServicesContext);
  const { content } = useContext(ContentfulContext);
  const {
    forgotPasswordTitle,
    forgotPasswordDescription,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    username
  } = content?.account as Account;
  const { validationErrorMessage } = content as Language;

  const buttons = content?.buttonsCollection?.items;
  const validations = content?.validationsCollection?.items;

  const { setError } = useContext(ErrorContext);

  const [result, setResult] = useState<OperationResultType>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>();

  type Form = {
    username: string;
  };

  const controls: Form = {
    username: "username"
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data: unknown) => {
    setLoading(true);

    const { username } = data as Form;

    authApi
      .forgotPassword({
        username
      })
      .then(() => {
        setResult("SUCCESS");
        setLoading(false);
        analyticsEvent(gaCategory.passwordReset, gaAction.submit, gaLabel.success);
      })
      .catch((error) => {
        setLoading(false);
        analyticsEvent(gaCategory.passwordReset, gaAction.submit, gaLabel.error);

        if (error?.response?.status === 401) {
          setResult("ERROR");
          return;
        }

        error.response.json().then((json: ErrorResponse) => {
          console.error(json);

          if (json?.errorCode) {
            setErrorCode(json?.errorCode);
          }

          // Bussiness logic errors are 4xx
          if (Math.floor(error?.response?.status / 100) === 4) {
            setResult("ERROR");
            return;
          }

          // Otherwise it's an unexpected server error
          setError(error);
        });
      });
  };

  const resetForm = () => {
    setResult(null);
  };

  return (
    <Box>
      <PageTitle title={forgotPasswordTitle} />
      <Typography variant="h1" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 8 }}>
        {forgotPasswordTitle}
      </Typography>
      <RenderContent
        content={forgotPasswordDescription?.json}
        sx={{ mb: 8, textAlign: "center" }}
      />

      {!result && (
        <Paper
          component={"form"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 4
          }}>
          <Input
            autoFocus
            required
            id={controls.username}
            label={username as string}
            error={!!errors.username}
            helperText={errors.username && (errors.username.message as string)}
            name={controls.username}
            rules={{
              required: getValidationMsg(validations, "required")
            }}
            control={control}
          />

          <ButtonContainer sx={{ mt: 5 }}>
            <NavButton
              route={routes.login}
              label={getButton(buttons, "back")}
              variant={ButtonVariant.secondary}
            />
            <Button
              loading={loading}
              onClick={handleSubmit(onSubmit)}
              label={getButton(buttons, "send")}
              variant={ButtonVariant.primary}
            />
          </ButtonContainer>
        </Paper>
      )}

      {result && (
        <OperationResult
          result={result}
          successTitle={forgotPasswordSuccess as string}
          errorTitle={
            errorCode
              ? `${validationErrorMessage} ${getValidationMsg(validations, errorCode)}`
              : (forgotPasswordFailure as string)
          }>
          {result === "ERROR" && (
            <Button
              onClick={resetForm}
              label={getButton(buttons, "tryAgain")}
              variant={ButtonVariant.primary}
            />
          )}
        </OperationResult>
      )}
    </Box>
  );
};

export default PasswordResetPage;
