import LanguageIcon from "@mui/icons-material/Language";
import { Box, Popover, Typography } from "@mui/material";
import { Suspense, useContext, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { ApiContext } from "../../context/ApiContext";
import { ContentfulContext } from "../../context/ContentfulContext";
import { GoogleServicesContext } from "../../context/GoogleServicesContext";
import { LanguageContext } from "../../context/LanguageContext";
import { UserContext } from "../../context/UserContext";
import { Header, Language } from "../../models/schema";
import { gaAction, gaCategory, gaLabel } from "../../utils/analytics";
import Button, { ButtonVariant } from "./Button";

const FlagIcon = ({ language }: { language: Language }) => {
  return <CircleFlag countryCode={language.flag as string} height="60" />;
};

const LanguageButtons = ({
  availableLanguages,
  activeLanguage,
  onClick
}: {
  availableLanguages: Language[];
  activeLanguage: Language;
  onClick: (lang: Language) => void;
}) => (
  <>
    {availableLanguages.map((lang) => (
      <Button
        onClick={() => onClick(lang)}
        key={lang.code}
        startIcon={
          <Box
            sx={{
              pl: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <FlagIcon language={lang} />
          </Box>
        }
        variant={
          activeLanguage?.name === lang.name ? ButtonVariant.primary : ButtonVariant.secondary
        }
        label={lang?.name as string}
        sx={{
          width: "100%",
          "& > div, & div > p": {
            width: "100%"
          }
        }}
      />
    ))}
  </>
);

export const LanguageSelector = ({ mobile }: { mobile?: boolean }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { authApi } = useContext(ApiContext);
  const { analyticsEvent } = useContext(GoogleServicesContext);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (lang: Language) => {
    analyticsEvent(gaCategory.language, gaAction.click, lang.code as string);
    languageCtx.setLanguage(lang.code as string);

    if (user) {
      const updatedLocale = lang.code === "sk" ? "SK" : "EN";

      authApi
        .updateLocale({ value: updatedLocale })
        .then((user) => {
          setUser(user);
          analyticsEvent(gaCategory.language, gaAction.submit, gaLabel.success);
        })
        .catch((error) => {
          analyticsEvent(gaCategory.language, gaAction.submit, gaLabel.error);

          if (error?.response?.status === 401) {
            return;
          }

          error.response.json().then((json: unknown) => {
            console.error(json);
          });
        })
        .finally(() => window.location.reload());
    } else {
      // We need to refresh the page in order to change the language everywhere ( eg. Google Recaptcha )
      window.location.reload();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const languageCtx = useContext(LanguageContext);
  const availableLanguages = languageCtx.availableLanguages;

  const language = availableLanguages?.find((lang) => lang.code === languageCtx.selectedLanguage);

  const { content } = useContext(ContentfulContext);
  const { user, setUser } = useContext(UserContext);

  const { language: languageLabel } = content?.header as Header;

  return (
    <Suspense fallback={<></>}>
      {availableLanguages?.length > 1 && (
        <>
          {mobile && (
            <Box>
              <Typography variant="h5" mb={3}>
                {languageLabel}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& > :not(template) ~ :not(template)": {
                    mt: 2
                  }
                }}>
                <LanguageButtons
                  onClick={onClick}
                  activeLanguage={language as Language}
                  availableLanguages={availableLanguages}
                />
              </Box>
            </Box>
          )}

          {!mobile && (
            <>
              <Button
                onClick={handleOpen}
                startIcon={<LanguageIcon />}
                variant={ButtonVariant.accent}
              />
              <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
                sx={{ mt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "& > :not(template) ~ :not(template)": {
                      mt: 2
                    },
                    justifyContent: "center"
                  }}>
                  <LanguageButtons
                    onClick={onClick}
                    activeLanguage={language as Language}
                    availableLanguages={availableLanguages}
                  />
                </Box>
              </Popover>
            </>
          )}
        </>
      )}
    </Suspense>
  );
};
