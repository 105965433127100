import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Slider,
  Typography
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  useForm
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  StockColorsEnum,
  StockDesignsEnum,
  StockEditionsEnum,
  StockSizeEnum,
  StockSurfacesEnum,
  StockTechnicalParametersEnum,
  StockThicknessEnum,
  StockTypesEnum
} from "../../api";
import {
  colorFontMapping,
  colorMapping,
  colorOptions,
  designOptions,
  editionOptions,
  sizeOptions,
  StockFilters,
  surfaceOptions,
  technicalOptions,
  thicknessOptions,
  typeOptions
} from "../../constants/stock-attributes";
import { ContentfulContext } from "../../context/ContentfulContext";
import {
  ColorAttribute,
  DesignAttribute,
  EditionAttribute,
  Product,
  Products,
  SurfaceAttribute,
  TechnicalAttribute,
  TypeAttribute
} from "../../models/schema";
import { getButton } from "../../querries/getters";
import useKeyPress from "../../utils/useKeyPress";
import { formatPrice, smoothScroll } from "../../utils/utilities";
import Button, { ButtonVariant } from "../utils/Button";
import ButtonContainer from "../utils/ButtonContainer";
import Input from "../utils/Input";
import { ProductFilterSelect } from "./ProductFilterSelect";

const MAX_PRICE = 80;
const MIN_PRICE = 0;
const PRICE_STEP = 5;

const MAX_STOCK = 3000;
const MIN_STOCK = 0;
const STOCK_STEP = 100;

const SUBMIT_BUTTON_ID = "submit-form-button";

export type FilterParams = {
  // General
  size?: StockSizeEnum[];
  thickness?: StockThicknessEnum[];
  // Attributes
  type?: StockTypesEnum[];
  discount?: boolean;
  discontinued?: boolean;
  preparing?: boolean;
  new?: boolean;
  design?: StockDesignsEnum[];
  surface?: StockSurfacesEnum[];
  technical?: StockTechnicalParametersEnum[];
  color?: StockColorsEnum[];
  // Additional
  name?: string;
  price?: number;
  quantity?: number;
};

type ProductsFilterProps = {
  onFilterChange: (data: FilterParams) => void;
};

const ProductsFilter = ({ onFilterChange }: ProductsFilterProps) => {
  const { content } = useContext(ContentfulContext);

  const [expanded, setExpanded] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialFiltering, setInitialFiltering] = useState(false);
  const [closeAllSelectPopups, setCloseAllSelectPopups] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  // On enter we want to submit the current state of filter form. Due to Select component might having focus, we need to trigger close event.
  useKeyPress(["Enter"], () => {
    setCloseAllSelectPopups(true);
    submitFormUsingButton();

    // Reset the flag after 1s
    setTimeout(() => {
      setCloseAllSelectPopups(false);
    }, 1000);
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {
    filterTitle,
    size,
    thickness,
    type: typeLabel,
    color: colorLabel,
    design: designLabel,
    surface: surfaceLabel,
    technical: technicalLabel,
    name,
    price,
    stock,
    reset,
    expand,
    collapse,
    notSet
  } = content?.products as Products;
  const buttons = content?.buttonsCollection?.items;

  const { meterUnit, pieceUnit3 } = content?.product as Product;

  const typeAttributes = content?.typeAttributesCollection?.items as TypeAttribute[];
  const editionAttributes = content?.editionAttributesCollection?.items as EditionAttribute[];
  const technicalAttributes = content?.technicalAttributesCollection?.items as TechnicalAttribute[];
  const surfaceAttributes = content?.surfaceAttributesCollection?.items as SurfaceAttribute[];
  const designAttributes = content?.designAttributesCollection?.items as DesignAttribute[];
  const colorAttributes = content?.colorAttributesCollection?.items as ColorAttribute[];

  const type = useMemo(() => typeOptions(typeAttributes), [typeAttributes]);
  const edition = useMemo(() => editionOptions(editionAttributes), [editionAttributes]);
  const technical = useMemo(() => technicalOptions(technicalAttributes), [technicalAttributes]);
  const surface = useMemo(() => surfaceOptions(surfaceAttributes), [surfaceAttributes]);
  const design = useMemo(() => designOptions(designAttributes), [designAttributes]);
  const color = useMemo(() => colorOptions(colorAttributes), [colorAttributes]);

  const controls = {
    type: StockFilters.Type,
    size: StockFilters.Size,
    thickness: StockFilters.Thickness,
    design: StockFilters.Design,
    surface: StockFilters.Surface,
    technical: StockFilters.Technical,
    discount: StockFilters.Discount,
    discontinued: StockFilters.Discontinued,
    preparing: StockFilters.Preparing,
    new: StockFilters.New,
    color: StockFilters.Color,
    price: StockFilters.Price,
    quantity: StockFilters.Quantity,
    name: StockFilters.Name
  };

  const { control, handleSubmit, setValue, getValues, reset: resetForm } = useForm();

  const submitFormUsingButton = () => {
    const button = document.getElementById(SUBMIT_BUTTON_ID);

    if (button) {
      button.focus();
      button.click();
    }
  };

  const onSubmit = (data: unknown) => {
    const {
      type,
      size,
      thickness,
      design,
      surface,
      technical,
      discount,
      discontinued,
      preparing,
      new: isNew,
      color,
      name,
      price,
      quantity
    } = data as FilterParams;

    // Delete previous values, and add only the ones that are selected
    searchParams.delete(StockFilters.Type);
    searchParams.delete(StockFilters.Discount);
    searchParams.delete(StockFilters.Discontinued);
    searchParams.delete(StockFilters.Preparing);
    searchParams.delete(StockFilters.New);
    searchParams.delete(StockFilters.Size);
    searchParams.delete(StockFilters.Thickness);
    searchParams.delete(StockFilters.Design);
    searchParams.delete(StockFilters.Surface);
    searchParams.delete(StockFilters.Technical);
    searchParams.delete(StockFilters.Color);
    searchParams.delete(StockFilters.Name);
    searchParams.delete(StockFilters.Price);
    searchParams.delete(StockFilters.Quantity);

    if (type?.length) {
      searchParams.append(StockFilters.Type, type.join(","));
    }

    if (discount) {
      searchParams.append(StockFilters.Discount, `${discount}`);
    }

    if (discontinued) {
      searchParams.append(StockFilters.Discontinued, `${discontinued}`);
    }

    if (preparing) {
      searchParams.append(StockFilters.Preparing, `${preparing}`);
    }

    if (isNew) {
      searchParams.append(StockFilters.New, `${isNew}`);
    }

    if (size?.length) {
      searchParams.append(StockFilters.Size, size.join(","));
    }

    if (thickness?.length) {
      searchParams.append(StockFilters.Thickness, thickness.join(","));
    }

    if (design?.length) {
      searchParams.append(StockFilters.Design, design.join(","));
    }
    if (surface?.length) {
      searchParams.append(StockFilters.Surface, surface.join(","));
    }

    if (technical?.length) {
      searchParams.append(StockFilters.Technical, technical.join(","));
    }

    if (color?.length) {
      searchParams.append(StockFilters.Color, color.join(","));
    }

    if (name?.length) {
      searchParams.append(StockFilters.Name, name);
    }

    if (price && !isNaN(price)) {
      searchParams.append(StockFilters.Price, price.toString());
    }

    if (quantity && !isNaN(quantity)) {
      searchParams.append(StockFilters.Quantity, quantity.toString());
    }

    setSearchParams(searchParams, {
      replace: true
    });

    onFilterChange(data as FilterParams);

    if (initialFiltering) {
      smoothScroll(SUBMIT_BUTTON_ID);
    }
  };

  // useEffect(() => {
  //   const subscription = watch(() => {
  //     const values = getValues([
  //       "type",
  //       "size",
  //       "thickness",
  //       "design",
  //       "surface",
  //       "technical",
  //       "discount",
  //       "discontinued",
  //       "preparing",
  //       "new",
  //       "color",
  //       "name",
  //       "price",
  //       "quantity"
  //     ]);

  //     console.log(values);
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch, getValues]);

  // Fill form based on search params on init
  useEffect(() => {
    const typeParam = searchParams.get(StockFilters.Type);
    const discountParam = searchParams.get(StockFilters.Discount);
    const discontinuedParam = searchParams.get(StockFilters.Discontinued);
    const preparingParam = searchParams.get(StockFilters.Preparing);
    const newParam = searchParams.get(StockFilters.New);
    const sizeParam = searchParams.get(StockFilters.Size);
    const thicknessParam = searchParams.get(StockFilters.Thickness);
    const designParam = searchParams.get(StockFilters.Design);
    const surfaceParam = searchParams.get(StockFilters.Surface);
    const technicalParam = searchParams.get(StockFilters.Technical);
    const colorParam = searchParams.get(StockFilters.Color);
    const nameParam = searchParams.get(StockFilters.Name);
    const priceParam = searchParams.get(StockFilters.Price);
    const quantityParam = searchParams.get(StockFilters.Quantity);

    if (typeParam) {
      const typeOptions =
        typeParam.split(",")?.filter((val) => type.some((opt) => opt.value === val)) ?? [];

      setValue(controls.type, typeOptions);
    }

    if (discountParam) {
      setValue(controls.discount, true);
    }

    if (discontinuedParam) {
      setValue(controls.discontinued, true);
    }

    if (preparingParam) {
      setValue(controls.preparing, true);
    }

    if (newParam) {
      setValue(controls.new, true);
    }

    if (sizeParam) {
      const sizes =
        sizeParam.split(",")?.filter((size) => sizeOptions.some((opt) => opt.value === size)) ?? [];

      setValue(controls.size, sizes);
    }

    if (thicknessParam) {
      const thickness =
        thicknessParam
          .split(",")
          ?.filter((thickness) => thicknessOptions.some((opt) => opt.value === thickness)) ?? [];

      setValue(controls.thickness, thickness);
    }

    if (designParam) {
      const designOptions =
        designParam.split(",")?.filter((val) => design.some((opt) => opt.value === val)) ?? [];

      setValue(controls.design, designOptions);
    }

    if (surfaceParam) {
      const surfaceOptions =
        surfaceParam.split(",")?.filter((val) => surface.some((opt) => opt.value === val)) ?? [];

      setValue(controls.surface, surfaceOptions);
    }

    if (technicalParam) {
      const technicalOptions =
        technicalParam.split(",")?.filter((val) => technical.some((opt) => opt.value === val)) ??
        [];

      setValue(controls.technical, technicalOptions);
    }

    if (colorParam) {
      const colorOptions =
        colorParam.split(",")?.filter((val) => color.some((opt) => opt.value === val)) ?? [];

      setValue(controls.color, colorOptions);
    }

    if (nameParam) {
      setValue(controls.name, nameParam);
    }

    if (priceParam && +priceParam >= 0) {
      setValue(controls.price, +priceParam);
    }

    if (quantityParam && +quantityParam >= 0) {
      setValue(controls.quantity, +quantityParam);
    }

    submitFormUsingButton();
    setInitialFiltering(true);
  }, []);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 4,
        mb: 6
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%"
        }}>
        <Typography variant="h4" fontWeight="bold" textAlign="center">
          {filterTitle}
        </Typography>

        <ButtonContainer>
          <Button
            sx={{ width: "9rem" }}
            variant={ButtonVariant.secondary}
            onClick={() => setExpanded((prev) => !prev)}
            label={(expanded ? collapse : expand) as string}
            endIcon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          />
        </ButtonContainer>
      </Box>

      <Collapse in={expanded} timeout="auto" sx={{ width: "100%" }}>
        <Grid container spacing={3} my={3}>
          <Grid item xs={12} md={6} lg={3}>
            <Input
              fullWidth
              id={controls.name}
              label={name as string}
              name={controls.name}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="body2" mb={1}>
              {typeLabel}
            </Typography>

            <ProductFilterSelect
              close={closeAllSelectPopups}
              name={controls.type}
              control={control}
              options={type}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="body2" mb={1}>
              {size}
            </Typography>

            <ProductFilterSelect
              close={closeAllSelectPopups}
              name={controls.size}
              control={control}
              options={sizeOptions}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="body2" mb={1}>
              {thickness}
            </Typography>

            <ProductFilterSelect
              close={closeAllSelectPopups}
              name={controls.thickness}
              control={control}
              options={thicknessOptions}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="body2" mb={1}>
              {designLabel}
            </Typography>

            <ProductFilterSelect
              close={closeAllSelectPopups}
              name={controls.design}
              control={control}
              options={design}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="body2" mb={1}>
              {surfaceLabel}
            </Typography>

            <ProductFilterSelect
              close={closeAllSelectPopups}
              name={controls.surface}
              control={control}
              options={surface}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="body2" mb={1}>
              {technicalLabel}
            </Typography>

            <ProductFilterSelect
              close={closeAllSelectPopups}
              name={controls.technical}
              control={control}
              options={technical}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="body2" mb={1}>
              {colorLabel}
            </Typography>

            <ProductFilterSelect
              close={closeAllSelectPopups}
              name={controls.color}
              control={control}
              renderValue={(
                selected: string[],
                field: ControllerRenderProps<FieldValues, FieldPath<FieldValues>>
              ) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected?.map((value: string) => (
                    <Chip
                      sx={{
                        backgroundImage:
                          (value as StockColorsEnum) === StockColorsEnum.Other
                            ? "conic-gradient(red 0deg 120deg, #009f05 120deg 240deg, blue 240deg 360deg)"
                            : "none",
                        backgroundColor: colorMapping[value as StockColorsEnum],
                        color: colorFontMapping[value as StockColorsEnum],
                        border: "1px solid rgba(0, 0, 0, 0.26)"
                      }}
                      onMouseDown={(e) => {
                        e.stopPropagation();
                      }}
                      onDelete={() => {
                        const currentValue = field.value as string[];
                        const newValue = currentValue?.filter((v) => v !== value);

                        field.onChange(newValue);
                      }}
                      key={value}
                      label={color.find((opt) => opt.value === value)?.label}
                    />
                  ))}
                </Box>
              )}>
              {color?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <>
                    <Box
                      sx={(theme) => ({
                        width: theme.spacing(3),
                        height: theme.spacing(3),
                        borderRadius: "50%",
                        border: "1px solid rgba(0, 0, 0, 0.26)",
                        backgroundImage:
                          (option.value as StockColorsEnum) === StockColorsEnum.Other
                            ? "conic-gradient(red 0deg 120deg, #009f05 120deg 240deg, blue 240deg 360deg)"
                            : "none",
                        backgroundColor: colorMapping[option.value as StockColorsEnum],
                        mr: 2
                      })}
                    />
                    {option.label}
                  </>
                </MenuItem>
              ))}
            </ProductFilterSelect>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: { xs: "start", lg: "center" },
                justifyContent: "space-evenly",
                flexDirection: { xs: "column", lg: "row" }
              }}>
              {/* Discount checkbox */}
              <Controller
                name={controls.discount}
                control={control}
                render={({ field }) => (
                  <FormControl
                    required
                    sx={(theme) => ({
                      color: theme.palette.text.primary
                    })}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!field.value}
                          sx={(theme) => ({
                            color: theme.palette.text.primary
                          })}
                          {...field}
                        />
                      }
                      label={
                        edition?.find((opt) => opt.value === StockEditionsEnum.Discount)?.label
                      }
                      color="primary"
                    />
                  </FormControl>
                )}
              />

              {/* Preparing checkbox */}
              <Controller
                name={controls.preparing}
                control={control}
                render={({ field }) => (
                  <FormControl
                    required
                    sx={(theme) => ({
                      color: theme.palette.text.primary
                    })}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!field.value}
                          sx={(theme) => ({
                            color: theme.palette.text.primary
                          })}
                          {...field}
                        />
                      }
                      label={
                        edition?.find((opt) => opt.value === StockEditionsEnum.Preparing)?.label
                      }
                      color="primary"
                    />
                  </FormControl>
                )}
              />

              {/* News checkbox */}
              <Controller
                name={controls.new}
                control={control}
                render={({ field }) => (
                  <FormControl
                    required
                    sx={(theme) => ({
                      color: theme.palette.text.primary
                    })}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!field.value}
                          sx={(theme) => ({
                            color: theme.palette.text.primary
                          })}
                          {...field}
                        />
                      }
                      label={edition?.find((opt) => opt.value === StockEditionsEnum.New)?.label}
                      color="primary"
                    />
                  </FormControl>
                )}
              />

              {/* Discontinued checkbox */}
              <Controller
                name={controls.discontinued}
                control={control}
                render={({ field }) => (
                  <FormControl
                    required
                    sx={(theme) => ({
                      color: theme.palette.text.primary
                    })}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!field.value}
                          sx={(theme) => ({
                            color: theme.palette.text.primary
                          })}
                          {...field}
                        />
                      }
                      label={
                        edition?.find((opt) => opt.value === StockEditionsEnum.Discontinued)?.label
                      }
                      color="primary"
                    />
                  </FormControl>
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="body2" mb={1}>
              {price} -{" "}
              {getValues(controls.price) ? formatPrice(getValues(controls.price)) : notSet}
            </Typography>

            <Controller
              name={controls.price}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box sx={{ width: "100%", px: 2 }}>
                  <Slider
                    sx={{ py: 4 }}
                    value={value ?? MIN_PRICE}
                    onChange={onChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => formatPrice(value)}
                    marks
                    disableSwap
                    min={MIN_PRICE}
                    max={MAX_PRICE}
                    step={PRICE_STEP}
                  />
                </Box>
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Typography variant="body2" mb={1}>
              {stock} -{" "}
              {getValues(controls.quantity)
                ? `${getValues(controls.quantity)} ${meterUnit} / ${pieceUnit3}`
                : notSet}
            </Typography>

            <Controller
              name={controls.quantity}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Box sx={{ width: "100%", px: 2 }}>
                  <Slider
                    sx={{ py: 4 }}
                    value={value ?? MIN_STOCK}
                    onChange={onChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}  ${meterUnit} / ${pieceUnit3}`}
                    marks
                    disableSwap
                    min={MIN_STOCK}
                    max={MAX_STOCK}
                    step={STOCK_STEP}
                  />
                </Box>
              )}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}>
          <ButtonContainer sx={{ mt: 5 }}>
            <Button
              variant={ButtonVariant.secondary}
              onClick={() => {
                resetForm();
                submitFormUsingButton();
              }}
              label={reset as string}
              endIcon={<ReplayIcon />}
            />
            <Button
              id={SUBMIT_BUTTON_ID}
              variant={ButtonVariant.primary}
              onClick={handleSubmit(onSubmit)}
              label={getButton(buttons, "confirm")}
              endIcon={<FilterAltIcon />}
            />
          </ButtonContainer>
        </Box>
      </Collapse>

      <Button
        sx={{
          display: scrollPosition > 1200 ? "flex" : "none",
          position: "fixed",
          zIndex: 800,
          bottom: 0,
          right: 0,
          m: 2
        }}
        variant={ButtonVariant.primary}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        endIcon={<ArrowUpwardIcon />}
      />
    </Paper>
  );
};

export default ProductsFilter;
