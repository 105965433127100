import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Box, Popover, Typography } from "@mui/material";
import { Suspense, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserDto } from "../../api";
import { routes } from "../../constants/routes";
import { ApiContext } from "../../context/ApiContext";
import { ContentfulContext } from "../../context/ContentfulContext";
import { GoogleServicesContext } from "../../context/GoogleServicesContext";
import { UserContext } from "../../context/UserContext";
import { Button as ButtonModel } from "../../models/schema";
import { getButton } from "../../querries/getters";
import { colors } from "../../theme/colors";
import { gaAction, gaCategory } from "../../utils/analytics";
import Button, { ButtonSize, ButtonVariant } from "./Button";
import NavButton from "./NavButton";

const AccountActions = ({
  buttons,
  user,
  logout,
  handleClose
}: {
  buttons: ButtonModel[];
  user?: UserDto;
  logout: () => void;
  handleClose?: () => void;
}) => {
  const { analyticsEvent } = useContext(GoogleServicesContext);

  return user ? (
    <>
      <Typography variant="h5" mb={2} fontWeight="bold" textAlign="center">
        {user.companyName}
      </Typography>

      <NavButton
        onClick={() => {
          handleClose?.();
          analyticsEvent(gaCategory.passwordChange, gaAction.click);
        }}
        route={routes.passwordChange}
        label={getButton(buttons, "changePassword")}
        variant={ButtonVariant.secondary}
        size={ButtonSize.full}
      />

      <Button
        onClick={logout}
        label={getButton(buttons, "logout")}
        variant={ButtonVariant.secondary}
        size={ButtonSize.full}
      />
    </>
  ) : (
    <>
      <NavButton
        onClick={() => {
          handleClose?.();
          analyticsEvent(gaCategory.login, gaAction.click);
        }}
        route={routes.login}
        label={getButton(buttons, "login")}
        variant={ButtonVariant.secondary}
        size={ButtonSize.full}
      />

      <NavButton
        onClick={() => {
          handleClose?.();
          analyticsEvent(gaCategory.partner, gaAction.click);
        }}
        route={routes.partner}
        label={getButton(buttons, "becomePartner")}
        variant={ButtonVariant.primary}
        size={ButtonSize.full}
      />
    </>
  );
};

export const AccountAvatar = ({
  mobile,
  setMobileNavOpen
}: {
  mobile?: boolean;
  setMobileNavOpen?: () => void;
}) => {
  const { authApi } = useContext(ApiContext);
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { user, setUser } = useContext(UserContext);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    handleClose();
    if (mobile && setMobileNavOpen) {
      setMobileNavOpen();
    }

    authApi
      .logout()
      .then(() => {
        setUser(undefined);
      })
      .catch(() => {
        setUser(undefined);
      });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { content } = useContext(ContentfulContext);
  const buttons = content?.buttonsCollection?.items;

  return (
    <Suspense fallback={<></>}>
      {mobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& > :not(template) ~ :not(template)": {
              mt: 2
            }
          }}>
          <AccountActions
            buttons={buttons as ButtonModel[]}
            user={user}
            logout={logout}
            handleClose={setMobileNavOpen}
          />
        </Box>
      )}

      {!mobile && (
        <>
          <Button
            onClick={handleOpen}
            startIcon={user ? <AccountCircleIcon /> : <AccountCircleOutlinedIcon />}
            variant={ButtonVariant.accent}
            sx={
              [routes.login, routes.passwordChange, routes.passwordReset, routes.partner].includes(
                location.pathname
              )
                ? {
                    color: colors.green,
                    backgroundColor: "transparent",
                    border: `3px solid ${colors.green}`
                  }
                : undefined
            }
          />
          <Popover
            id={id}
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            sx={{ mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "& > :not(template) ~ :not(template)": {
                  mt: 2
                },
                justifyContent: "center"
              }}>
              <AccountActions
                buttons={buttons as ButtonModel[]}
                user={user}
                logout={logout}
                handleClose={handleClose}
              />
            </Box>
          </Popover>
        </>
      )}
    </Suspense>
  );
};
