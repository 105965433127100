/* eslint-disable @typescript-eslint/no-redeclare */
import {
  Box,
  Button as MuiButton,
  CircularProgress,
  SxProps,
  Typography,
  useTheme
} from "@mui/material";
import React, { CSSProperties, FC, PropsWithChildren } from "react";
import { colors } from "../../theme/colors";

export type ButtonVariant = "primary" | "secondary" | "accent";
export const ButtonVariant = {
  primary: "primary" as ButtonVariant,
  secondary: "secondary" as ButtonVariant,
  accent: "accent" as ButtonVariant
};

export type ButtonSize = "auto" | "full";
export const ButtonSize = {
  auto: "auto" as ButtonSize,
  full: "full" as ButtonSize
};
export type ButtonProps = {
  label?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  sx?: SxProps;
  type?: "submit" | "button";
  disabled?: boolean;
  href?: string;
  loading?: boolean;
  id?: string;
};

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  label,
  startIcon,
  endIcon,
  variant = "primary",
  size = "auto",
  type = "button",
  onClick,
  onMouseDown,
  onMouseUp,
  sx,
  disabled = false,
  href,
  loading,
  children,
  id
}) => {
  const stylesMap: { [key: string]: CSSProperties } = {
    primary: {
      color: `${colors.white} !important`,
      background: `linear-gradient(84deg, ${colors.brown} 0%, ${colors.lightBrown} 100%)`
    },
    secondary: {
      color: `${colors.brown} !important`,
      backgroundColor: "transparent",
      border: `3px solid ${colors.brown}`
    },
    accent: {
      color: `${colors.white} !important`,
      backgroundColor: "transparent",
      border: `3px solid ${colors.white}`
    }
  };

  const theme = useTheme();

  return (
    <MuiButton
      id={id}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      type={type}
      sx={{
        borderRadius: 3,
        filter: "drop-shadow(-4px 5px 4px rgba(0, 0, 0, 0.25))",
        ":hover": {
          opacity: 0.8
        },
        minHeight: 50,
        minWidth: 50,
        width: size === "full" ? "100%" : "auto",
        ...stylesMap[variant],
        ...sx
      }}
      href={href}
      disabled={disabled || loading}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {!loading && (
          <>
            {startIcon && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& svg, & img": {
                    width: theme.spacing(5),
                    height: theme.spacing(5)
                  }
                }}
                mr={label && 2}>
                {startIcon}
              </Box>
            )}
            {label && (
              <Typography sx={{ mx: { xs: 1.5, md: 2 } }} fontWeight="900">
                {label}
              </Typography>
            )}
            {endIcon && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& svg": {
                    width: theme.spacing(5),
                    height: theme.spacing(5)
                  }
                }}
                ml={label && 2}>
                {endIcon}
              </Box>
            )}
          </>
        )}
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center"
            }}>
            <CircularProgress
              size={theme.spacing(5)}
              sx={{
                color: stylesMap[variant].color
              }}
            />
          </Box>
        )}
      </Box>
      {children}
    </MuiButton>
  );
};

export default Button;
