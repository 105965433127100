import { Paper, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { routes } from "../../constants/routes";
import { ContentfulContext } from "../../context/ContentfulContext";
import { GoogleServicesContext } from "../../context/GoogleServicesContext";
import { PartnerForm } from "../../models/schema";
import { getButton } from "../../querries/getters";
import { gaAction, gaCategory } from "../../utils/analytics";
import { ButtonSize, ButtonVariant } from "../utils/Button";
import NavButton from "../utils/NavButton";
import RenderContent from "../utils/RenderContent";
import ImportantPartners from "./ImportantPartners";

const BecomePartnerBanner = () => {
  const { analyticsEvent } = useContext(GoogleServicesContext);
  const { content } = useContext(ContentfulContext);
  const { interestedTitle, interestedDescription } = content?.becomePartner as PartnerForm;
  const buttons = content?.buttonsCollection?.items;
  const theme = useTheme();

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 4
      }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center" mb={{ xs: 4, md: 6 }}>
        {interestedTitle}
      </Typography>

      <RenderContent content={interestedDescription?.json} sx={{ mb: 6, textAlign: "center" }} />

      <NavButton
        route={routes.partner}
        onClick={() => analyticsEvent(gaCategory.partner, gaAction.click)}
        label={getButton(buttons, "becomePartner")}
        variant={ButtonVariant.primary}
        size={ButtonSize.full}
        sx={{
          width: theme.spacing(40),
          mb: 6
        }}
      />

      <ImportantPartners wrapper={false} />
    </Paper>
  );
};

export default BecomePartnerBanner;
