import { Box, Chip, FormControl, MenuItem, Select } from "@mui/material";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldPath,
  FieldValues
} from "react-hook-form";
import { SelectOption } from "../../constants/stock-attributes";
import { ContentfulContext } from "../../context/ContentfulContext";
import { getButton } from "../../querries/getters";
import Button, { ButtonSize, ButtonVariant } from "../utils/Button";

export const ProductFilterSelect = ({
  name,
  control,
  options,
  renderValue,
  children,
  close
}: {
  name: string;
  control: Control<FieldValues, unknown>;
  options?: SelectOption[];
  renderValue?: (
    value: string[],
    field: ControllerRenderProps<FieldValues, FieldPath<FieldValues>>
  ) => ReactNode;
  children?: ReactNode;
  close?: boolean;
}) => {
  const { content } = useContext(ContentfulContext);
  const buttons = content?.buttonsCollection?.items;

  const [open, setOpen] = useState(false);

  const isMobile = useMemo(() => window.innerWidth < 600, []);

  // If close is triggered, we close the select popup
  useEffect(() => {
    if (close) {
      setOpen(false);
    }
  }, [close]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl sx={{ width: "100%" }}>
          <Select
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            multiple
            MenuProps={{
              sx: isMobile
                ? {
                    backgroundColor: "rgba(0,0,0,0.5)",
                    "& .MuiMenu-paper": {
                      top: "90px !important",
                      maxHeight: "calc(100vh - 230px)"
                    }
                  }
                : {}
            }}
            renderValue={
              renderValue
                ? (selected) => renderValue(selected, field)
                : (selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5
                      }}>
                      {selected?.map((value: string) => (
                        <Chip
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                          onDelete={() => {
                            const currentValue = field.value as string[];
                            const newValue = currentValue?.filter((v) => v !== value);

                            field.onChange(newValue);
                          }}
                          key={value}
                          label={options?.find((opt) => opt.value === value)?.label}
                        />
                      ))}
                    </Box>
                  )
            }
            id={name}
            variant="outlined"
            {...field}
            value={field?.value ?? []}>
            {children ??
              options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>

          {isMobile && open && (
            <Box
              sx={{
                position: "fixed",
                display: "flex",
                bottom: 0,
                left: 0,
                px: "20px",
                pb: 2,
                width: "100%",
                justifyContent: "center",
                zIndex: 1305 // Must be greater than 1300, which is the z-index of the backdrop
              }}>
              <Button
                sx={{ maxWidth: "calc(100% - 32px)" }}
                variant={ButtonVariant.primary}
                size={ButtonSize.full}
                onClick={() => setOpen(false)}
                label={getButton(buttons, "confirm")}
              />
            </Box>
          )}
        </FormControl>
      )}
    />
  );
};
