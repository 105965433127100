import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { Box, SxProps } from "@mui/material";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { generateId } from "../../utils/utilities";
import Button, { ButtonVariant } from "../utils/Button";

export type SelectionListProps = {
  itemsLength: number;
  minLengthForControls: number;
  children: ReactNode;
  scrollSize: number;
  sx?: SxProps;
};

let timer: NodeJS.Timeout;

const SelectionList = ({
  children,
  itemsLength,
  minLengthForControls,
  scrollSize,
  sx = {}
}: SelectionListProps) => {
  const [scrollContainerId] = useState(`${generateId(6)}-scroll-container`);
  const [isMouseHolding, setIsMouseHolding] = useState(false);
  const [direction, setDirection] = useState<"left" | "right">("right");
  const scrollContainer = document.getElementById(scrollContainerId);

  const stop = () => {
    clearInterval(timer);
    setIsMouseHolding(false);
  };

  const scrollItems = useCallback(() => {
    if (scrollContainer) {
      scrollContainer.scrollLeft =
        direction === "left"
          ? scrollContainer.scrollLeft - scrollSize
          : scrollContainer.scrollLeft + scrollSize;
    }
  }, [direction, scrollContainer, scrollSize]);

  useEffect(() => {
    if (isMouseHolding) {
      //if holding mouse, we trigger `repeat`
      scrollItems();
      timer = setInterval(scrollItems, 400);
    } else {
      stop(); //stop it if releasing mouse holding
    }
  }, [isMouseHolding, scrollItems]); //to track mouse holding behaviour changes

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        ...sx
      }}>
      {itemsLength >= minLengthForControls && (
        <Button
          disabled={scrollContainer?.scrollLeft === 0}
          variant={ButtonVariant.secondary}
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{
            display: { xs: "none", md: "flex" },
            mr: 3
          }}
          onMouseDown={() => {
            setDirection("left");
            setIsMouseHolding(true);
          }}
          onMouseUp={stop}
        />
      )}

      <Box
        id={scrollContainerId}
        sx={() => ({
          display: "flex",
          overflow: "auto",
          overflowY: "hidden",
          scrollBehavior: "smooth",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none"
          }
        })}>
        {children}
      </Box>

      {itemsLength >= minLengthForControls && (
        <Button
          disabled={
            scrollContainer
              ? scrollContainer.scrollLeft + scrollContainer.clientWidth ===
                scrollContainer.scrollWidth
              : false
          }
          variant={ButtonVariant.secondary}
          startIcon={<ArrowForwardOutlinedIcon />}
          sx={{
            display: { xs: "none", md: "flex" },
            ml: 3
          }}
          onMouseDown={() => {
            setDirection("right");
            setIsMouseHolding(true);
          }}
          onMouseUp={stop}
        />
      )}
    </Box>
  );
};

export default SelectionList;
