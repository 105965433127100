export const gaCategory = {
  aboutUs: "about_us",
  contact: "contact",
  productsFilter: "products_filter",
  product: "product",
  partner: "partner",
  cart: "cart",
  addToCart: "add_to_cart",
  removeFromCart: "remove_from_cart",
  login: "login",
  passwordChange: "password_change",
  passwordReset: "password_reset",
  social: "social",
  cookies: "cookies",
  language: "language"
};

export const gaAction = {
  click: "click",
  submit: "submit"
};

export const gaLabel = {
  success: "success",
  error: "error"
};
