import React from "react";
import {
  AuthControllerApi,
  ContactControllerApi,
  OrderControllerApi,
  StockControllerApi
} from "../api";

interface ApiContextSchema {
  authApi: AuthControllerApi;
  orderApi: OrderControllerApi;
  contactApi: ContactControllerApi;
  stockApi: StockControllerApi;
}

export const ApiContext = React.createContext<ApiContextSchema>({} as ApiContextSchema);
