import { Cookies } from "react-cookie";
import {
  CART,
  CHANGE_PASSWORD_REDIRECT,
  COLOR_MODE,
  CONTENTFUL_DATA,
  LANGUAGE,
  NECESSARY_COOKIES,
  OPTIONAL_COOKIES
} from "../constants/global";

const cookies = new Cookies();
const storage = localStorage;

const necessaryItems = [
  CART,
  LANGUAGE,
  NECESSARY_COOKIES,
  OPTIONAL_COOKIES,
  CHANGE_PASSWORD_REDIRECT
]; // Neccessary items, that will be stored regardless of the cookie settings
const functionalItems = [COLOR_MODE]; // Functional items, that will be stored if the user allows functional cookies

const sessionItems = [CART, LANGUAGE, CHANGE_PASSWORD_REDIRECT]; // Short term items that will be stored for the length of the session in session storage (also due to their size)
const cookieItems = [COLOR_MODE, NECESSARY_COOKIES, OPTIONAL_COOKIES]; // Long term items that will be stored in cookies

export const getItem = (name: string) => {
  const item = sessionItems.includes(name)
    ? storage.getItem(name)
    : cookieItems.includes(name)
    ? cookies.get(name)
    : null;

  if (!item) {
    return null;
  }

  try {
    const parsed = JSON.parse(item);
    return parsed;
  } catch (e) {
    return item;
  }
};
export const setItem = (name: string, value: unknown) => {
  if (value === null || value === undefined) {
    return removeItem(name);
  }

  if (
    !necessaryItems.includes(name) &&
    !functionalItems.includes(name) &&
    !name.includes(CONTENTFUL_DATA)
  ) {
    return console.error(`Item ${name} is not defined as necessary or functional.`);
  }

  const valueStringified = typeof value === "string" ? value : JSON.stringify(value);

  if (necessaryItems.includes(name) || functionalItemsEnabled || name.includes(CONTENTFUL_DATA)) {
    if (sessionItems.includes(name) || name.includes(CONTENTFUL_DATA)) {
      return storage.setItem(name, valueStringified);
    }

    if (cookieItems.includes(name)) {
      return cookies.set(name, valueStringified, {
        sameSite: "lax"
      });
    }

    return console.error(`Item ${name} is not defined as cookie or session storage item.`);
  }
};

export const removeItem = (name: string) => {
  if (sessionItems.includes(name)) {
    return storage.removeItem(name);
  }

  if (cookieItems.includes(name)) {
    return cookies.remove(name);
  }

  return console.error(`Item ${name} is not defined as cookie or session storage item.`);
};

export const removeFunctionalItems = () => functionalItems.forEach((item) => removeItem(item));

const functionalItemsEnabled = getItem(OPTIONAL_COOKIES) === true;
