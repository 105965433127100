import {
  AuthControllerApi,
  Configuration,
  ContactControllerApi,
  OrderControllerApi,
  ResponseContext,
  StockControllerApi
} from "../api";

const basePath = process.env.REACT_APP_API_BASE_PATH;

const config = (handleUnathorized: () => void) =>
  new Configuration({
    basePath,
    middleware: [
      {
        post(context: ResponseContext) {
          // If the response status is 401 Unathorized or 403 Forbidden, call the handleUnathorized function
          if ([401, 403].includes(context.response.status)) {
            handleUnathorized();
          }

          return new Promise((resolve) => {
            resolve(context.response);
          });
        }
      }
    ]
  });

export const authApiFactory = (handleUnathorized: () => void) =>
  new AuthControllerApi(config(handleUnathorized));
export const orderApiFactory = (handleUnathorized: () => void) =>
  new OrderControllerApi(config(handleUnathorized));
export const contactApiFactory = (handleUnathorized: () => void) =>
  new ContactControllerApi(config(handleUnathorized));
export const stockApiFactory = (handleUnathorized: () => void) =>
  new StockControllerApi(config(handleUnathorized));
