import CookieIcon from "@mui/icons-material/Cookie";
import { Alert, Box, Container, Paper, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { NECESSARY_COOKIES, OPTIONAL_COOKIES } from "../../constants/global";
import { ContentfulContext } from "../../context/ContentfulContext";
import { GoogleServicesContext } from "../../context/GoogleServicesContext";
import { Cookies } from "../../models/schema";
import { gaAction, gaCategory } from "../../utils/analytics";
import { getItem, removeFunctionalItems, setItem } from "../../utils/storage";
import Button, { ButtonVariant } from "./Button";
import ButtonContainer from "./ButtonContainer";
import RenderContent from "./RenderContent";

const CookieConsent = ({ type = "text" }: { type?: "text" | "alert" }) => {
  const [neccessaryCookies, setNeccessaryCookies] = useState<boolean>(getItem(NECESSARY_COOKIES));

  const {
    setNeccessaryCookiesAllowed,
    setOptionalCookiesAllowed,
    analyticsEvent,
    optionalCookiesAllowed
  } = useContext(GoogleServicesContext);

  const [open, setOpen] = useState<boolean>(!neccessaryCookies);

  const { content } = useContext(ContentfulContext);
  const { title, description, allowAll, allowNeccessary, allowNeccessaryAlert, close } =
    content.cookies as Cookies;

  return (
    <>
      {type === "text" && (
        <Typography
          sx={{
            cursor: "pointer",
            textDecoration: "underline"
          }}
          onClick={() => {
            setOpen(true);
            analyticsEvent(gaCategory.cookies, gaAction.click);
          }}>
          {title}
        </Typography>
      )}

      {type === "alert" && !neccessaryCookies && (
        <Alert severity="warning">
          <RenderContent content={allowNeccessaryAlert?.json} />
        </Alert>
      )}

      {open && (
        <Box
          sx={{
            position: "fixed",
            zIndex: 1000,
            bottom: 0,
            left: 0,
            width: "100%"
          }}>
          <Container maxWidth="xl" sx={{ p: { xs: 0 } }}>
            <Paper
              sx={{
                width: "100%",
                overflowY: "auto",
                boxShadow: "1px -5px 4px 0px rgba(0, 0, 0, 0.25)",
                p: 4,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }}>
              <Typography
                variant="h5"
                fontWeight="bold"
                mb={{ xs: 2, xl: 4 }}
                sx={{ display: "flex", alignItems: "center" }}>
                <CookieIcon fontSize="large" sx={{ mr: 3 }} />
                {title}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", xl: "row" },
                  justifyContent: { xs: "center", xl: "space-between" }
                }}>
                <RenderContent content={description?.json} />
                <ButtonContainer sx={{ mt: { xs: 3, xl: 0 }, ml: { xs: 0, xl: 5 } }}>
                  <Button
                    onClick={() => {
                      setItem(NECESSARY_COOKIES, true);
                      setItem(OPTIONAL_COOKIES, true);

                      setNeccessaryCookies(true);
                      setNeccessaryCookiesAllowed(true);
                      setOptionalCookiesAllowed(true);

                      setOpen(false);
                      analyticsEvent(gaCategory.cookies, gaAction.click, "Allow all cookies");
                    }}
                    label={allowAll as string}
                    variant={ButtonVariant.primary}
                  />
                  <Button
                    onClick={() => {
                      const optionalPreviouslyAllowed = optionalCookiesAllowed;

                      setItem(NECESSARY_COOKIES, true);
                      setItem(OPTIONAL_COOKIES, false);
                      setNeccessaryCookies(true);
                      setNeccessaryCookiesAllowed(true);
                      setOptionalCookiesAllowed(false);

                      removeFunctionalItems();
                      setOpen(false);
                      analyticsEvent(gaCategory.cookies, gaAction.click, "Only necessary cookies");

                      if (optionalPreviouslyAllowed) {
                        // We need to refresh the page in order to unload the analytics scripts and stop tracking
                        window.location.reload();
                      }
                    }}
                    label={allowNeccessary as string}
                    variant={ButtonVariant.secondary}
                  />
                  {neccessaryCookies && (
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                      label={close as string}
                      variant={ButtonVariant.secondary}
                    />
                  )}
                </ButtonContainer>
              </Box>
            </Paper>
          </Container>
        </Box>
      )}
    </>
  );
};

export default CookieConsent;
