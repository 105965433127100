import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Anchors, routes } from "../../constants/routes";
import { ContentfulContext } from "../../context/ContentfulContext";
import { GoogleServicesContext } from "../../context/GoogleServicesContext";
import { Asset, Footer as FooterModel } from "../../models/schema";
import { getButton } from "../../querries/getters";
import { colors } from "../../theme/colors";
import { gaAction, gaCategory } from "../../utils/analytics";
import { smoothScroll } from "../../utils/utilities";
import Button, { ButtonVariant } from "../utils/Button";
import CookieConsent from "../utils/CookieConsent";
import NavigationPanel from "../utils/NavigationPanel";
import SocialButton from "../utils/SocialButton";
import Logo from "./Logo";

const Footer = () => {
  const { content } = useContext(ContentfulContext);
  const { analyticsEvent } = useContext(GoogleServicesContext);
  const [anchorToNavigate, setAnchorToNavigate] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    if (anchorToNavigate) {
      smoothScroll(anchorToNavigate);
      setAnchorToNavigate(null);
    }
  }, [location]);

  const footer = content?.footer as FooterModel;
  const buttons = content?.buttonsCollection?.items;

  const navigateHomeWithScroll = (anchor: string) => {
    analyticsEvent(gaCategory.aboutUs, gaAction.click, anchor);
    setAnchorToNavigate(anchor);
    navigate(routes.home);
  };

  return (
    <footer style={{ flexShrink: 0 }}>
      <Container
        maxWidth="xl"
        sx={{
          width: "100%",
          p: { xs: 8, md: 10 },
          mt: { xs: 6, md: 8 },
          color: "white",
          background: `linear-gradient(84deg, ${colors.brown} 0%, ${colors.lightBrown} 100%)`,
          borderTopLeftRadius: { xs: 70, md: 100 },
          borderTopRightRadius: { xs: 20, md: 30 }
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 5
          }}>
          <Link
            to={routes.home}
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}>
            <Logo variant="footer" />
          </Link>
        </Box>

        <Grid container spacing={8}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center"
            }}>
            <Typography variant="h5" fontWeight="bold" mb={5}>
              {footer.navigation}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "& > :not(template) ~ :not(template)": {
                  mt: 2
                }
              }}>
              <Button
                variant={ButtonVariant.accent}
                label={getButton(buttons, "news")}
                onClick={() => navigateHomeWithScroll(Anchors.news)}
                sx={{
                  width: theme.spacing(40)
                }}
              />
              <Button
                variant={ButtonVariant.accent}
                label={getButton(buttons, "aboutUs")}
                onClick={() => navigateHomeWithScroll(Anchors.aboutUs)}
                sx={{
                  width: theme.spacing(40)
                }}
              />
              <Button
                variant={ButtonVariant.accent}
                label={getButton(buttons, "showroom")}
                onClick={() => navigateHomeWithScroll(Anchors.showroom)}
                sx={{
                  width: theme.spacing(40)
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center"
            }}>
            <Typography variant="h5" fontWeight="bold" mb={5}>
              {footer.sections}
            </Typography>
            <NavigationPanel variant="footer" />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center"
            }}>
            <Typography variant="h5" fontWeight="bold" mb={5}>
              {footer.followUs}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "& > :not(template) ~ :not(template)": {
                  mt: 2
                }
              }}>
              <SocialButton
                network="facebook"
                link={footer.facebook as string}
                logo={footer.facebookLogo as Asset}
              />
              <SocialButton
                network="instagram"
                link={footer.instagram as string}
                logo={footer.instagramLogo as Asset}
              />
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 10
          }}>
          <CookieConsent />
          <Typography sx={{ mt: 3 }}>{footer.disclaimer}</Typography>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
