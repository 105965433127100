import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Maybe, Scalars } from "../../models/schema";
import { getPageTitle } from "../../utils/utilities";

const PageTitle = ({ title }: { title?: Maybe<Scalars["String"]["output"]> }) => {
  const location = useLocation();

  useEffect(() => {
    const pageTitle = getPageTitle(title);
    document.title = pageTitle;
  }, [location, title]);

  return null;
};

export default PageTitle;
