import React, { MutableRefObject } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export interface GoogleServicesContextSchema {
  badgeVisible: boolean;
  showBadge: () => void;
  hideBadge: () => void;
  recaptchaRef: MutableRefObject<ReCAPTCHA | null>;
  neccessaryCookiesAllowed: boolean;
  optionalCookiesAllowed: boolean;
  setNeccessaryCookiesAllowed: (value: boolean) => void;
  setOptionalCookiesAllowed: (value: boolean) => void;
  analyticsSend: (page: string, title?: string) => void;
  analyticsEvent: (category: string, action: string, label?: string, value?: number) => void;
}

export const GoogleServicesContext = React.createContext<GoogleServicesContextSchema>(
  {} as GoogleServicesContextSchema
);
