import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GoogleServicesContext } from "../../context/GoogleServicesContext";

const GoogleAnalytics = () => {
  const { optionalCookiesAllowed, analyticsSend } = useContext(GoogleServicesContext);

  const location = useLocation();

  // Initialize Google Analytics when optional cookies are allowed
  useEffect(() => {
    if (optionalCookiesAllowed && location?.pathname) {
      analyticsSend(location.pathname);
    }
  }, [location, optionalCookiesAllowed]);

  return <></>;
};

export default GoogleAnalytics;
